import React, {Component} from 'react';
import { Container } from 'react-bootstrap';
import Login from '../../components/Util/Login/Login.js'
import './Home.css'
import '../../CSS_share.css'


class Home extends Component{
    
    render(){
        return (
            <div style={{'min-height':'100vh'}}>
                <div id="test" className="home-container">
                </div>
                <Container className="login-container-position">  
                    <Login/>
                </Container>  
            </div>
        );
    }
}

export default Home;