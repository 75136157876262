import React from "react";
import App from "./App";
import { createStore, applyMiddleware, compose} from "redux";
import { Provider } from "react-redux";
import firebaseConfig  from './config/firebaseConfig';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore' ;
import 'firebase/storage';
import 'firebase/functions';
import rootReducer from "./store/reducers/rootReducer";
import thunk from 'redux-thunk';
import { getFirebase, ReactReduxFirebaseProvider }  from 'react-redux-firebase'
import { getFirestore, createFirestoreInstance } from 'redux-firestore';


const rrfConfig = {
    userProfile: 'users',
    useFirestoreForProfile: true
  };

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const auth = firebase.auth();
const functions = firebase.functions();
const storage = firebase.storage();
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({trace: true}) : compose;


const initialState = {}
const store = createStore(
    rootReducer, 
    initialState,
    composeEnhancer(applyMiddleware( thunk.withExtraArgument({ getFirebase, getFirestore }) ))
  );
  
const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance
  };

const FirebaseRedux = () => {
    return (
        <React.StrictMode>
            <Provider store={store}>
                <ReactReduxFirebaseProvider {...rrfProps}>
                    <App />
                </ReactReduxFirebaseProvider>
            </Provider>
        </React.StrictMode>
    );
}

/**
 * Call the scoring function for a given user ID with additional parameters.
 * @param {string} userId - String of Firestore ID in the sessions collection
 * @param {boolean} selfAssessmentScoring - Whether to include self-assessment scoring
 * @param {boolean} oralScoring - Whether to include oral scoring
 * @param {boolean} writtenScoring - Whether to include written scoring
 */
const callScoringFunction = async (userId, selfAssessmentScoring, oralScoring, writtenScoring) => {
  //console.log("Scoring function was called", selfAssessmentScoring, oralScoring, writtenScoring);

  const functionUrl = "https://scoring-zpl7azwzia-uc.a.run.app";
  
  const queryParams = new URLSearchParams({
    user_id: userId,
    self_assessment_scoring: selfAssessmentScoring,
    oral_scoring: oralScoring,
    written_scoring: writtenScoring
  });

  const response = await fetch(`${functionUrl}?${queryParams.toString()}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error("Failed to call scoring function");
  }

  return response.json();
};

export { db, auth, storage, functions, FirebaseRedux, callScoringFunction };

