import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  withRouter,
} from "react-router-dom";
import PrivateRoute from "./PrivateRoute.js";
import AdminRoute from "./AdminRoute.js";
import Container from "react-bootstrap/Container";
import { useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";

// Screens

// Home Navigation
import Home from "../views/Home/Home.js";
import LoginScreen from "../views/Auth/LoginScreen.js";
import ResetPasswordScreen from "../views/Auth/PasswordReset.js";

import ModulePage from "../views/Dashboard/ModulePage.js";

import ContentUpload from "../views/Dashboard/ContentUpload.js";
import SignUpPage from "../views/Home/SignUp.js";

// Onboarding workflow
import CompletionPage from "../views/Onboarding/CompletionPage";
import WelcomePage from "../views/Onboarding/WelcomePage.js";
import ChangePasswordScreen from "../views/Onboarding/ChangePasswordScreen.js";
import ConsentForm from "../views/Onboarding/ConsentForm.js";
import DemoForm from "../views/Onboarding/DemoForm.js";
import AIPostSurveyForm from "../views/Onboarding/AIPostSurveyForm.js";
import WrittenPostSurveyForm from "../views/Onboarding/WrittenPostSurveyForm";
// Dashboard Pages
import OralBuildingPage from "../views/Dashboard/Pages/OralBuildingPage.js";
import AdminPanel from "../views/Dashboard/Pages/AdminPanel";
import WrittenBuildingPage from "../views/Dashboard/Pages/WrittenBuildingPage.js";
import FAQPage from "../views/Dashboard/Pages/FAQPage.js";
import AnnotationPage from "../views/Dashboard/Pages/AnnotationPage.js";
import ResourcePage from "../views/Dashboard/Pages/ResourcePage.js";
import DashboardPage from "../views/Dashboard/Pages/DashboardPage.js";
import ConsentPage from "../views/Dashboard/Pages/ConsentPage.js";
import DemographicPage from "../views/Dashboard/Pages/DemographicPage.js";
import AddUsers from "../views/Onboarding/AddUsers";
import AIResourcePage from "../views/Dashboard/Pages/AcademicIntegrityResourcePage.js";
import OralResourcePage from "../views/Dashboard/Pages/OralResourcePage.js";
import WrittenResourcePage from "../views/Dashboard/Pages/WrittenResourcePage.js";
import Welcome from "../components/Onboarding/Welcome.js";
import IncompleteModule from "../views/Dashboard/Pages/IncompleteModule.js";
import OralPostSurveyForm from "../views/Onboarding/OralPostSurveyForm.js";
import ReportPage from "../views/Dashboard/Pages/ReportPage.js";
import DetailedDashboard from "../components/Util/Report/DetailedDashboard";

export function AuthIsLoaded({ children }) {
  const auth = useSelector((state) => state.firebase.auth);
  if (!isLoaded(auth))
    return (
      <Container
        fluid
        className="text-center center-align"
        style={{ minHeight: 1500 }}
      >
        {" "}
        loading...{" "}
      </Container>
    );
  return children;
}

function RootNavigations() {
  return (
    <AuthIsLoaded>
      <Router>
        <Switch>
          <Route path="/" exact component={Home}></Route>

          <Route path="/login" exact component={LoginScreen}></Route>
          <Route
            path="/resetPassword"
            exact
            component={ResetPasswordScreen}
          ></Route>
          <PrivateRoute
            path="/onboarding"
            exact
            component={WelcomePage}
          ></PrivateRoute>
          <PrivateRoute
            path="/welcome1"
            exact
            component={CompletionPage}
          ></PrivateRoute>

          <PrivateRoute
            path="/onboarding/change_password"
            exact
            component={ChangePasswordScreen}
          ></PrivateRoute>
          <PrivateRoute
            path="/onboarding/consent_form"
            exact
            component={ConsentForm}
          ></PrivateRoute>
          <PrivateRoute
            path="/onboarding/student_survey1"
            exact
            component={DemoForm}
          ></PrivateRoute>
          <PrivateRoute
            path="/knowledge/postSurvey"
            exact
            component={AIPostSurveyForm}
          ></PrivateRoute>
          <PrivateRoute
            path="/oral/postSurvey"
            exact
            component={OralPostSurveyForm}
          ></PrivateRoute>

          <PrivateRoute
            path="/written/postSurvey"
            exact
            component={WrittenPostSurveyForm}
          ></PrivateRoute>

          <PrivateRoute
            path={[
              "/knowledge/:moduleId",
              "/oral/:moduleId",
              "/written/:moduleId",
            ]}
            component={withRouter(ModulePage)}
          ></PrivateRoute>
          <PrivateRoute
            path="/overview"
            exact
            component={DashboardPage}
          ></PrivateRoute>
          <PrivateRoute
            path="/dashboard"
            exact
            component={ReportPage}
          ></PrivateRoute>
          <PrivateRoute
            path="/oral"
            exact
            component={OralBuildingPage}
          ></PrivateRoute>
          <PrivateRoute
            path="/written"
            exact
            component={WrittenBuildingPage}
          ></PrivateRoute>
          <PrivateRoute path="/faq" exact component={FAQPage} />
          <PrivateRoute path="/resources" exact component={ResourcePage} />
          <PrivateRoute path="/annotation" exact component={AnnotationPage} />
          <PrivateRoute
            path="/resources/academicIntegrity"
            exact
            component={AIResourcePage}
          />
          <PrivateRoute
            path="/resources/oral"
            exact
            component={OralResourcePage}
          />
          <PrivateRoute
            path="/resources/written"
            exact
            component={WrittenResourcePage}
          />
          <PrivateRoute path="/admin/module" component={ContentUpload} />
          <PrivateRoute path="/incompleteModule" component={IncompleteModule} />
          <PrivateRoute path="/signup" component={SignUpPage} />
          <AdminRoute path="/test" component={DetailedDashboard}></AdminRoute>
          <AdminRoute path="/consent" component={ConsentPage}></AdminRoute>
          <AdminRoute path="/admin/welcome" component={Welcome}></AdminRoute>
          <AdminRoute
            path="/demographic"
            exact
            component={DemographicPage}
          ></AdminRoute>
          <AdminRoute path="/admin" component={AddUsers}></AdminRoute>
          <AdminRoute path="/adminPanel" component={AdminPanel}></AdminRoute>
          <Redirect from="*" to={"/"} />
        </Switch>
      </Router>
    </AuthIsLoaded>
  );
}

export default RootNavigations;
