import React from "react";
import DetailedCard from "./DetailedCard";
import { Grid } from "@material-ui/core";

// DATA FORMAT:
// const data = [
//   {
//     header: "Overall",
//     description:
//       "Your overall score calculated as an average of your reading, writing, speaking and listening scores.",
//     score: {
//       score: 31,
//       errorBar: 5,
//       selfAssessment: 90,
//     },
//   },
// ];

const DetailedDashboardNew = ({ data }) => {
  return (
    <Grid container spacing={2}>
      {data.map((itm) => (
        itm?.details && (
          <Grid item xs={12}>
            <DetailedCard data={itm} />
          </Grid>
        )
      ))}
    </Grid>
  );
};

export default DetailedDashboardNew;
