import React from "react";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Area,
  ComposedChart,
  ResponsiveContainer,
} from "recharts";
import gaussian from "gaussian";

const generateData = () => {
  const data = [];
  let distribution = gaussian(60, 100);
  for (let i = 30; i <= 90; i++) {
    data.push({ name: i, uv: distribution.pdf(i) });
  }
  return data;
};

const NormalDistributionChart = ({ score = 55 }) => {
  // let newData = _.cloneDeep(data);
  let newData = generateData();
  let index = newData.findIndex((itm) => itm.name === score);
  if (index == -1) {
    let pushIndex = newData.findIndex((itm) => itm.name > score);
    let distribution = gaussian(60, 100);
    newData.splice(pushIndex, 0, {
      name: score,
      uv: distribution.pdf(score),
      score: 0.04,
    });
  } else {
    newData[index].score = 0.04;
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={350}
        height={150}
        data={newData}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="name" type="number" domain={[20, 100]} />
        <YAxis dataKey="uv" type="number" tick={false} />
        <CartesianGrid strokeDasharray="3 3" />
        <Area
          type="monotone"
          dataKey="uv"
          stroke="#8884d8"
          fillOpacity={1}
          fill="url(#colorUv)"
        />
        <Bar dataKey="score" barSize={3} fill="#413ea0" />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default NormalDistributionChart;
