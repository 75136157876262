import React, { useState, useRef } from "react";
import Papa from "papaparse";
import "./annotation.css";
import { useSelector } from "react-redux"; // or react-redux-firebase
import { db } from "../../../FirestoreRedux";

/** 
 * A simple token-based diff approach to mark "removed" or "added" tokens
 * comparing oldTokens vs newTokens. 
 */
function diffTokens(oldTokens, newTokens) {
  let oldDiff = [];
  let newDiff = [];

  let i = 0;
  let j = 0;
  while (i < oldTokens.length && j < newTokens.length) {
    if (oldTokens[i] === newTokens[j]) {
      oldDiff.push({ text: oldTokens[i], diff_state: "none" });
      newDiff.push({ text: newTokens[j], diff_state: "none" });
      i++;
      j++;
    } else {
      // mark old as removed, new as added
      oldDiff.push({ text: oldTokens[i], diff_state: "removed" });
      newDiff.push({ text: newTokens[j], diff_state: "added" });
      i++;
      j++;
    }
  }
  while (i < oldTokens.length) {
    oldDiff.push({ text: oldTokens[i], diff_state: "removed" });
    i++;
  }
  while (j < newTokens.length) {
    newDiff.push({ text: newTokens[j], diff_state: "added" });
    j++;
  }

  return [oldDiff, newDiff];
}

/** 
 * Split on words or punctuation.
 */
function tokenize(text) {
  return text.match(/[\w']+|[.,!?;]/g) || [];
}

const TAGS = ["important", "question", "clarification", "note"];

function Annotation() {
  // Get the uid of the current logged-in user
  const auth = useSelector((state) => state.firebase.auth);
  const uid = auth?.uid || null;

  const [csvData, setCsvData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [highlightIdCounter, setHighlightIdCounter] = useState(0);
  const [selectedTag, setSelectedTag] = useState(
    localStorage.getItem("selectedTag") || "important"
  );

  const fileInputRef = useRef(null);

  // Parse CSV and build diffed tokens + store original text
  const handleCsvUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    Papa.parse(file, {
      header: true,
      complete: (results) => {
        let newCsvData = [];
        results.data.forEach((row) => {
          if (!row.draft1 && !row.draft2) return;
          const draft1_text = row.draft1 || "";
          const draft2_text = row.draft2 || "";

          const oldTokens = tokenize(draft1_text);
          const newTokens = tokenize(draft2_text);
          const [diffOld, diffNew] = diffTokens(oldTokens, newTokens);

          newCsvData.push({
            student_id: row.student_id || "",
            draft1_text, // original text
            draft2_text, // original text
            draft1_tokens: diffOld, // array of { text, diff_state }
            draft2_tokens: diffNew,
            highlights: [] // array of { id, draft, start, end, tag, comment }
          });
        });
        setCsvData(newCsvData);
        setCurrentIndex(0);
        setHighlightIdCounter(0);
      }
    });
  };

  // If user hasn't uploaded a CSV yet, show upload form
  if (csvData.length === 0) {
    return (
      <div className="bg-gray-100 min-h-screen flex items-center justify-center">
        <div className="max-w-4xl w-full p-6 bg-white rounded-lg shadow-xl">
          <h1 className="text-2xl font-bold mb-4 text-gray-700">Upload CSV File</h1>
          <input
            type="file"
            accept=".csv"
            ref={fileInputRef}
            onChange={handleCsvUpload}
          />
        </div>
      </div>
    );
  }

  // The current row in our CSV
  const row = csvData[currentIndex];

  // Helper to create <span> for each token
  const renderDraft = (tokens, highlights, draftNumber) => {
    let highlightMap = {};
    highlights
      .filter((h) => h.draft === draftNumber)
      .forEach((h) => {
        for (let i = h.start; i <= h.end; i++) {
          highlightMap[i] = h;
        }
      });

    return tokens.map((tokenObj, i) => {
      const { text, diff_state } = tokenObj;
      let diffClasses = [];
      if (diff_state === "removed") diffClasses.push("removed");
      if (diff_state === "added") diffClasses.push("added");

      let h = highlightMap[i];
      let highlightClasses = [];
      let highlightAttr = {};
      if (h) {
        highlightClasses.push("highlight");
        highlightClasses.push(h.tag); // e.g. 'important'
        highlightAttr["data-hid"] = h.id;
      }

      let allClasses = ["token", ...diffClasses, ...highlightClasses].join(" ");
      return (
        <span
          key={i}
          className={allClasses}
          data-draft={draftNumber}
          data-idx={i}
          {...highlightAttr}
          style={{ marginRight: "2px" }}
        >
          {text}
        </span>
      );
    });
  };

  const buildHighlightsTable = (highlights) => {
    if (!highlights.length) {
      return <p>No highlights yet.</p>;
    }
    return (
      <table className="mt-2 border">
        <thead className="font-bold">
          <tr>
            <td>ID</td>
            <td>Draft</td>
            <td>Tokens</td>
            <td>Tag</td>
            <td>Comment</td>
            <td>Action</td>
          </tr>
        </thead>
        <tbody>
          {highlights.map((h) => (
            <tr key={h.id}>
              <td>{h.id}</td>
              <td>Draft {h.draft}</td>
              <td>{h.start} - {h.end}</td>
              <td>{h.tag}</td>
              <td>{h.comment || ""}</td>
              <td>(click highlight in text to remove)</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  // Function to push the current row's annotation info to Firestore
  async function saveCurrentRowToFirestore() {
    if (!uid) {
      alert("No user logged in, cannot save to Firestore.");
      return;
    }
    const r = csvData[currentIndex];
    const student_id = r.student_id || "unknown";

    // We'll separate highlights for draft1 vs. draft2
    const draft1Highlights = r.highlights
      .filter(h => h.draft === 1)
      .map(h => ({
        start_index: h.start,
        end_index: h.end,
        tag: h.tag,
        comment: h.comment || ""
      }));

    const draft2Highlights = r.highlights
      .filter(h => h.draft === 2)
      .map(h => ({
        start_index: h.start,
        end_index: h.end,
        tag: h.tag,
        comment: h.comment || ""
      }));

    try {
      // Overwrite the doc: users/{uid}/annotations/{student_id}
      // with two submaps: draft1 and draft2
      await db
        .collection("users")
        .doc(uid)
        .collection("annotations")
        .doc(student_id)
        .set({
          draft1: {
            content: r.draft1_text,
            highlights: draft1Highlights
          },
          draft2: {
            content: r.draft2_text,
            highlights: draft2Highlights
          }
        });
      console.log(`Saved row for student_id=${student_id} to Firestore.`);
    } catch (err) {
      console.error("Error saving to Firestore:", err);
      alert("Error saving to Firestore: " + err.message);
    }
  }

  // For "Next" we first save the current row, then move index
  const handleNext = async () => {
    await saveCurrentRowToFirestore();
    setCurrentIndex((idx) => Math.min(csvData.length - 1, idx + 1));
  };

  // For "Prev" we first save the current row, then move index
  const handlePrev = async () => {
    await saveCurrentRowToFirestore();
    setCurrentIndex((idx) => Math.max(0, idx - 1));
  };

  // Optional: a "Save" button to just manually save
  const handleSave = async () => {
    await saveCurrentRowToFirestore();
    alert("Annotations saved for this student.");
  };

  const handleTagChange = (e) => {
    setSelectedTag(e.target.value);
    localStorage.setItem("selectedTag", e.target.value);
  };

  // Called on mouseUp in the draft container
  const handleMouseUp = (draftNumber) => {
    const selection = window.getSelection();
    if (!selection.rangeCount) return;
    const range = selection.getRangeAt(0);
    if (!range || range.collapsed) return;

    const containerId = draftNumber === 1 ? "draft1Container" : "draft2Container";
    let container = document.getElementById(containerId);
    if (!container) return;

    let tokenEls = Array.from(container.querySelectorAll(".token"));
    const intersectsNode = (el) => {
      let r = document.createRange();
      r.selectNode(el);
      return range.intersectsNode(el);
    };
    let selectedTokens = tokenEls.filter(intersectsNode);
    if (!selectedTokens.length) return;

    let idxs = selectedTokens.map((el) => parseInt(el.dataset.idx, 10));
    let start = Math.min(...idxs);
    let end = Math.max(...idxs);

    let comment = prompt("Comments (optional):", "") || "";

    const newId = highlightIdCounter;
    setHighlightIdCounter(newId + 1);

    let newHighlight = {
      id: newId,
      draft: draftNumber,
      start,
      end,
      tag: selectedTag,
      comment
    };

    let newCsvData = [...csvData];
    newCsvData[currentIndex] = {
      ...newCsvData[currentIndex],
      highlights: [...newCsvData[currentIndex].highlights, newHighlight]
    };
    setCsvData(newCsvData);

    window.getSelection().removeAllRanges();
  };

  // Called on click inside the draft container to remove highlight if clicked
  const handleTokenClick = (e) => {
    let token = e.target.closest(".token");
    if (!token) return;
    let hid = token.dataset.hid;
    if (!hid) return;
    hid = parseInt(hid, 10);

    let newCsvData = [...csvData];
    let rowHighs = newCsvData[currentIndex].highlights;
    rowHighs = rowHighs.filter((h) => h.id !== hid);
    newCsvData[currentIndex] = {
      ...newCsvData[currentIndex],
      highlights: rowHighs
    };
    setCsvData(newCsvData);
  };

  const rowHighlightsTable = buildHighlightsTable(row.highlights);

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="max-w-5xl mx-auto p-6 bg-white rounded-lg shadow-xl mt-6">
        <h1 className="text-2xl font-bold mb-4 text-gray-700">Annotation</h1>

        <div className="tag-container">
          <span className="font-bold">Select Tag:</span>
          <select
            id="tagSelect"
            className="border px-2 py-1 rounded"
            value={selectedTag}
            onChange={handleTagChange}
          >
            {TAGS.map((t) => (
              <option key={t} value={t}>
                {t}
              </option>
            ))}
          </select>
        </div>

        <p className="text-sm text-gray-600">
          Select text in Draft 1 or Draft 2 to highlight with the chosen tag. You can enter an
          optional comment. Click an existing highlight to remove it.
        </p>

        <div className="grid grid-cols-2 gap-4 mt-4">
          {/* Draft 1 */}
          <div>
            <h2 className="text-lg font-semibold text-gray-700">Draft 1 (Original)</h2>
            <div
              id="draft1Container"
              className="draft-container border rounded-lg p-4 bg-gray-50 text-gray-800"
              onMouseUp={() => handleMouseUp(1)}
              onClick={handleTokenClick}
            >
              {renderDraft(row.draft1_tokens, row.highlights, 1)}
            </div>
          </div>
          {/* Draft 2 */}
          <div>
            <h2 className="text-lg font-semibold text-gray-700">Draft 2 (Updated)</h2>
            <div
              id="draft2Container"
              className="draft-container border rounded-lg p-4 bg-gray-50 text-gray-800"
              onMouseUp={() => handleMouseUp(2)}
              onClick={handleTokenClick}
            >
              {renderDraft(row.draft2_tokens, row.highlights, 2)}
            </div>
          </div>
        </div>

        <h2 className="text-xl font-semibold mt-6 text-gray-700">Current Highlights</h2>
        {rowHighlightsTable}

        <div className="mt-4">
          {/* Save button */}
          <button
            onClick={handleSave}
            className="myButton"
          >
            Save
          </button>{" "}
          {/* Show previous only if not at first row */}
          {currentIndex > 0 && (
            <button
              onClick={handlePrev}
              className="myButton"
            >
              Previous
            </button>
          )}
          {/* Show next only if not at last row */}
          {currentIndex < csvData.length - 1 && (
            <button
              onClick={handleNext}
              className="myButton"
            >
              Next
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Annotation;
