import React from "react";
import Annotation from "../../../components/Wrappers/Docs/annotation.js"
import DashFrame from '../../../components/DashFrame/DashFrame'
import '../../../CSS_share.css'

function AnnotationPage( props ) {
    return (
        <DashFrame>
            <Annotation/>
        </DashFrame>
    );
}
export default AnnotationPage;