import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TableRow, TableCell } from "@material-ui/core";

const AssessmentRow = ({ question, lightBg, handleResponse, index, response, disabled }) => {
  const classes = useStyles({ lightBg });

  const handleToggle = (event) => {
    handleResponse(index);
  };

  return (
    <TableRow className={classes.root}>
      <TableCell className={classes.question}>{question}</TableCell>
      <TableCell className={classes.radio}>
        <input
            name="isGoing"
            type="checkbox"
            disabled={disabled}
            checked={response}
            onClick={handleToggle} 
            />
      </TableCell>
 
    </TableRow>
  );
};

const useStyles = makeStyles({
  root: {
  },
  radio: {
    border: "solid",
    borderColor: "white",
    backgroundColor: (props) => (props.lightBg ? "#ffffff" : "#eeeeee"),
    borderWidth: "1px",
    textAlign: "center",
  },
  question: {
    border: "solid",
    borderColor: "white",
    backgroundColor: (props) => (props.lightBg ? "#ffffff" : "#eeeeee"),
    borderWidth: "1px",
    padding: "1rem",
  },
});

export default AssessmentRow;
