import React, {useState} from 'react';
import {TextField, Button, Grid, Typography} from '@material-ui/core';
// Backend Function
import {auth} from '../../../FirestoreRedux';

  
function ResetPassword() {
    const [email, setEmail] = useState('')

    function handleChange(event) {
        setEmail(event.target.value)
    }

    async function handleSubmit(event) {
        event.preventDefault();
        try {
            await auth.sendPasswordResetEmail(email)
            alert("Success! An email with further instructions should reach you shortly!")
        } catch (err) {
            if (err.code === 'auth/invalid-email' || err.code === 'auth/user-not-found') {
                alert("The email you provided was incorrect.")
            }
        }
    }

    return (
        <Grid container alignItems="center" spacing={3} justify="center"
        style={{ paddingLeft: "24%", paddingRight: "24%", paddingTop: "10%" }} >
            <Grid item xs={12}>
                <Typography variant="h4" color="textSecondary">Please enter the email that you signed up with</Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField fullWidth id="email" label="Email"  onChange={(event) => handleChange(event)} />
            </Grid>

            <Grid item xs={12}>
                
                    <Button variant="contained" color="primary" onClick={(event) => handleSubmit(event)}>
                        Submit
                    </Button>
            </Grid>
        </Grid>
    )

}

export default ResetPassword;