import React, { useMemo, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@material-ui/core";
import { StudentSelect } from "./UserView";
import Data from "./additional-data.json";

const nameMap = {
  oral: {
    "3_score": "Comprehension",
    "5_expVol": "Expression and Volume",
    "5_phras": "Phrasing",
    "5_smooth": "Smoothness",
    "5_pace": "Pacing",
    "6_speed": "Words per min.",
    "6_accuracy": "Accuracy",
    "6_prosody": "Prosody",
    "10_vocab": "Vocabulary",
    "10_gramm": "Grammar",
    "10_coher": "Coherence",
    "10_deliv": "Delivery",
    "10_pragm": "Pragmatic and Interactive Competence",
  },
  written: {
    "3_score": "Comprehension",
    "5_speed": "Speed",
    "5_accuracy": "Accuracy",
    "5_prosody": "Prosody",
    "6_vocab_size": "Vocabulary Size",
    "9_taskf": "Task Fulfillment",
    "9_criti": "Critical Skills",
    "9_orgco": "Organization and Coherence",
    "9_acade": "Academic Style",
    "9_vocab": "Vocabulary",
    "9_gramm": "Grammar",
  },
};

const namesWith4Score = [
  "Expression and Volume",
  "Phrasing",
  "Smoothness",
  "Pacing",
  "Prosody",
  "Vocabulary",
  "Grammar",
  "Coherence",
  "Delivery",
  "Pragmatic and Interactive Competence",
  "Task Fulfillment",
  "Critical Skills",
  "Organization and Coherence",
  "Academic Style",
];

const hasEmptyString = (obj) => {
  let hasEmpty = false;
  Object.values(obj).forEach((val) => {
    if (val === "") {
      hasEmpty = true;
    }
  });
  return hasEmpty;
};

const calculateAggregateMean = (data) => {
  let totalOralNulls = 0;
  let totalWrittenNulls = 0;
  const aggregatedTotal = data.reduce(
    (acc, curr) => {
      let oralHasEmpty = hasEmptyString(curr.oral_1);
      let writtenHasEmpty = hasEmptyString(curr.written_1);
      if (oralHasEmpty) {
        totalOralNulls++;
      }
      if (writtenHasEmpty) {
        totalWrittenNulls++;
      }
      return {
        oral: oralHasEmpty
          ? acc.oral
          : Object.keys(data[0].oral_1).reduce(
              (acc2, curr2) => ({
                ...acc2,
                [curr2]: acc.oral[curr2] + parseFloat(curr.oral_1[curr2]),
              }),
              {}
            ),
        written: writtenHasEmpty
          ? acc.written
          : Object.keys(data[0].written_1).reduce(
              (acc2, curr2) => ({
                ...acc2,
                [curr2]: acc.written[curr2] + parseFloat(curr.written_1[curr2]),
              }),
              {}
            ),
      };
    },
    {
      oral: Object.keys(data[0].oral_1).reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: 0,
        }),
        {}
      ),
      written: Object.keys(data[0].written_1).reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: 0,
        }),
        {}
      ),
    }
  );

  return {
    oral: Object.entries(aggregatedTotal.oral).reduce(
      (acc, [key, val]) => ({
        ...acc,
        [key]: Math.round((100 * val) / (data.length - totalOralNulls)) / 100,
      }),
      {}
    ),
    written: Object.entries(aggregatedTotal.written).reduce(
      (acc, [key, val]) => ({
        ...acc,
        [key]:
          Math.round((100 * val) / (data.length - totalWrittenNulls)) / 100,
      }),
      {}
    ),
  };
};

const getGraphData = (selected, type, data, aggregateMean) => {
  if (!selected) return null;
  const selectedEntry = data.find((entry) => entry.id === selected?.id);
  return Object.entries(aggregateMean[type]).map(([key, val]) => ({
    name: nameMap[type][key],
    Average: val,
    "Your Score":
      selectedEntry[`${type}_1`][key] === ""
        ? 0
        : parseFloat(selectedEntry[`${type}_1`][key]),
  }));
};

const Analysis = () => {
  const [selected, setSelected] = useState();

  const aggregateMean = useMemo(() => {
    return calculateAggregateMean(Data);
  });

  const graphDataOral = useMemo(() => {
    return getGraphData(selected, "oral", Data, aggregateMean);
  }, [selected]);

  const graphDataWritten = useMemo(() => {
    return getGraphData(selected, "written", Data, aggregateMean);
  }, [selected]);

  return (
    <>
      {Data && (
        <Grid item width="300px">
          <StudentSelect
            ids={Data.map((r) => r.id)}
            onSelect={(id) => setSelected(Data.find((r) => r.id === id))}
            value={selected?.id || ""}
          />
        </Grid>
      )}
      {selected && graphDataOral && graphDataWritten && (
        <Grid item container flexDirection="column" alignItems="center">
          <Typography variant="h3">Analysis</Typography>
          <Card sx={{ width: "100%" }}>
            <CardHeader
              title={"Oral"}
              sx={{
                backgroundColor: (theme) => theme.palette.primary.light,
                textAlign: "center",
              }}
            />
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Machine Scores
              </Typography>
              <Box sx={{ padding: 2, display: "flex" }}>
                <BarChart
                  width={500}
                  height={300}
                  data={graphDataOral
                    .filter((val) => namesWith4Score.includes(val.name))
                    .splice(0, 5)}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Average" fill="#8884d8" />
                  <Bar dataKey="Your Score" fill="#82ca9d" />
                </BarChart>

                <BarChart
                  width={500}
                  height={300}
                  data={graphDataOral
                    .filter((val) => namesWith4Score.includes(val.name))
                    .splice(-5)}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Average" fill="#8884d8" />
                  <Bar dataKey="Your Score" fill="#82ca9d" />
                </BarChart>
              </Box>
              <Box sx={{ padding: 2 }}>
                <Typography variant="h5" gutterBottom>
                  Objective Scores
                </Typography>
                <BarChart
                  width={500}
                  height={300}
                  data={graphDataOral.filter(
                    (val) => !namesWith4Score.includes(val.name)
                  )}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Average" fill="#8884d8" />
                  <Bar dataKey="Your Score" fill="#82ca9d" />
                </BarChart>
              </Box>
            </CardContent>
          </Card>

          <Card sx={{ width: "100%" }}>
            <CardHeader
              title={"Written"}
              sx={{
                backgroundColor: (theme) => theme.palette.primary.light,
                textAlign: "center",
              }}
            />
            <CardContent>
              <Box sx={{ padding: 2, display: "flex" }}>
                <Box>
                  <Typography variant="h5" gutterBottom>
                    Machine Scores
                  </Typography>
                  <BarChart
                    width={500}
                    height={300}
                    data={graphDataWritten.filter((val) =>
                      namesWith4Score.includes(val.name)
                    )}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Average" fill="#8884d8" />
                    <Bar dataKey="Your Score" fill="#82ca9d" />
                  </BarChart>
                </Box>
                <Box>
                  <Typography variant="h5" gutterBottom>
                    Objective Scores
                  </Typography>

                  <BarChart
                    width={500}
                    height={300}
                    data={graphDataWritten.filter(
                      (val) => !namesWith4Score.includes(val.name)
                    )}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Average" fill="#8884d8" />
                    <Bar dataKey="Your Score" fill="#82ca9d" />
                  </BarChart>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      )}
    </>
  );
};

export default Analysis;
