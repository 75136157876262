import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import VideoPlayer from '../../../Util/VideoPlayer/VideoPlayer.js';


import FirstDraftSilentReading from '../../../Util/PDFRender/FirstDraftSilentReading.js';
import SecondDraftSilentReading from '../../../Util/PDFRender/SecondDraftSilentReading.js';

import SelfAssessment from '../../../Util/SelfAssessment/SelfAssessment.js';
import SelfAssessmentSingle from '../../../Util/SelfAssessment/SelfAssessmentSingle.js';

import AsrInterface from "./../../../Util/Asr/interface/Interface";
import ReadAloud from './../../../Util/readAloud/ReadAloud';
import QuizWritten from '../../../Util/QuizWritten/QuizWritten.js';

import Upload from "./../../../Util/Asr/upload/Upload";
import PracticePresenting from "../../../Util/PracticePresenting/PracticePresenting"

// import AsrInterface from "./interface/Interface";

import Highlighter from './../../../Util/VocabHighlight/Highlighter.js';
import { Alert, AlertTitle } from '@material-ui/lab';
// import './LearningModule.css';
import { fetchModuleById, updateModuleData } from '../../../../store/actions/util/moduleActions.js';
import Quiz from '../../../Util/Quiz/Quiz.js'
import TopicTable from '../../../Util/TopicTable/TopicTable'
import AssignmentIcon from '@material-ui/icons/Assignment';
import VideocamIcon from '@material-ui/icons/Videocam';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';

import { ButtonGroup, Button, Tooltip, Typography, Grid } from '@material-ui/core';
import { storage, callScoringFunction } from '../../../../FirestoreRedux'
import './SubModuleWrapper.css'
import { AssignmentTurnedInOutlined, CommentOutlined, InfoOutlined } from '@material-ui/icons';
import ReadAnArticle from '../../../Util/ReadAnArticle/ReadAnArticle.js';
import ReactHtmlParser from 'react-html-parser';
import QuizNew from '../../../Util/QuizNew';

const titleMap = {
    "AcademicIntegrity": "Academic Integrity"
}
class SubModuleWrapper extends Component {

    constructor(props) {
        super(props);

        this.state = {
            subModuleData: this.props.content,
            pdfContent: "",
            alertTriggered: false,
            navigation: {
                currentSection: 0,
                nextSection: 1,
                prevSection: null,
                defaultSection: 0
            },
            moduleComplete: false,
            triggerChange: true,
            defaultSectionLoaded: false,
        }
        this.getQuizData = this.getQuizData.bind(this)
        this.updateMcqWithHints = this.updateMcqWithHints.bind(this)
        this.renderOnSubModuleSwitch = this.renderOnSubModuleSwitch.bind(this)
        this.toModule = this.toModule.bind(this)
    }

    componentDidMount() {
        if (this.state.defaultSectionLoaded === false) {
            this.renderDefaultSection();
            this.setState({ defaultSectionLoaded: true })
        }



    }

    componentDidUpdate() {
        if (this.state.defaultSectionLoaded === false) {
            this.renderDefaultSection();
            this.setState({ defaultSectionLoaded: true })
        }
    }

    renderOnSubModuleSwitch(value) {
        if (value) {
            this.setState(
                {
                    defaultSectionLoaded: false,
                    navigation: {
                        currentSection: 0,
                        nextSection: 1,
                        prevSection: null,
                        defaultSection: 0
                    }
                }
            );
        }
    }

    toModule = () => {
        try {
            this.props.onSwitchPageType('modulePage', 0);
        } catch (err) {
            console.log(err)
        }

    }

    renderDefaultSection() {
        try {
            const sections = Object.entries(this.props.content.content);
            this.renderSubModuleContent(0, 0, sections[this.state.navigation.defaultSection][1], false)
        } catch (err) {
            console.log(err)
        }
    }

    renderNavBar(subModuleData) {
        const alertUser = (sectionAlert) => {
            alert(sectionAlert)
            this.setState({alertTriggered: true})
        }
        return (
            <ButtonGroup disableElevation fullWidth className="shadow-sm">
                {subModuleData.map((item, index) =>
                    <Tooltip size='large' arrow={true} style={{ 'fontSize': "10em" }} title={item[1].sectionTitle}>
                        <Button
                            color={item[1].userState.locked ? "secondary" : index === this.state.navigation.currentSection ? "primary" : "default"}
                            key={index}
                            fullWidth
                            disableFocusRipple={true}
                            disableRipple={true}
                            disabled={item[1].userState.locked}
                            onClick={(item[1].sectionAlert === undefined || this.state.alertTriggered || subModuleData[index - 1][1].userState.locked || !subModuleData[index + 1][1].userState.locked) ? () => { this.renderSubModuleContent(item[0], subModuleData.length, item[1], true) } : () => alertUser(item[1].sectionAlert)}
                            variant={"contained"}
                            className="btn-arrow-right"
                            classes={{ 'disabled': { 'backgroundColor': "red" } }}
                            style={{ borderRadius: "3px", paddingLeft: "60px", height: "55px" }}>
                            {item[1].userState.completed ? <CheckCircleOutlineIcon color={index === this.state.navigation.currentSection ? "default" : "primary"} style={{ zIndex: 2 }} /> :
                                item[1].sectionIcon === "quiz" ? <AssignmentIcon /> :
                                    item[1].sectionIcon === "video" ? <VideocamIcon /> :
                                        item[1].sectionIcon === "info" ? <InfoOutlined /> :
                                            item[1].sectionIcon === "comment" ? <CommentOutlined /> :
                                                item[1].sectionIcon === "certificate" ? <AssignmentTurnedInOutlined /> :
                                                    item[1].sectionIcon === "readAloud" && <><RecordVoiceOverIcon></RecordVoiceOverIcon></>
                            }

                            <Typography variant='caption' style={{ fontSize: "9px", zIndex: 2, marginLeft: "5px" }}>{item[1].sectionIconTitle}</Typography>
                        </Button>
                    </Tooltip>
                )}
            </ButtonGroup>
        )
    }

    renderSubModuleContent(sectionIndex, numSections, sectionData, navFlag) {

        try {
            var moduleContent = document.getElementById("module-content")
            if (moduleContent) {
                moduleContent.innerHTML = "";
                // Create heading
                var heading = document.createElement("h4");
                heading.innerHTML = sectionData.sectionTitle
                moduleContent.appendChild(heading)
                moduleContent.appendChild(document.createElement("hr"))
                let hasQuiz = false;
                let modData = this.props.moduleData.content
                for (let subSectionKey in sectionData) {
                    this.renderSection(moduleContent, sectionData, sectionIndex, subSectionKey)

                    let sectionType = modData.subModules[this.props.subModuleId].content[sectionIndex][subSectionKey].type
                    if (sectionType === "quiz" || sectionType === "mcqWithHints") {
                        hasQuiz = true
                    } else {

                        if (modData.subModules[this.props.subModuleId].content[sectionIndex][subSectionKey].userState !== undefined) {
                            modData.subModules[this.props.subModuleId].content[sectionIndex][subSectionKey].userState.viewed = true
                        }
                    }

                }
                if (!hasQuiz) {
                    modData.subModules[this.props.subModuleId].content[sectionIndex].userState.completed = true
                   
                    if (modData.subModules[this.props.subModuleId].content[parseInt(sectionIndex)+1] !== undefined){
                        // If oral 1.2, lock the sub topic lecture. This is unlocked once the student answers all the questions in quiz
                        if (modData.type === 'oral' && modData.userState.progress < 16){
                            if (parseInt(sectionIndex) === 2) {
                                modData.subModules[this.props.subModuleId].content[1].userState.locked = true;
                            } else if (parseInt(sectionIndex) !== 0 || modData.subModules[this.props.subModuleId].content[2].userState.locked) {
                                console.log(parseInt(sectionIndex)+1)
                                modData.subModules[this.props.subModuleId].content[parseInt(sectionIndex)+1].userState.locked = false
                            }
                        } else {
                            modData.subModules[this.props.subModuleId].content[parseInt(sectionIndex)+1].userState.locked = false
                        }
                    }

                    this.props.updateModuleData(this.props.uid, modData)
                    callScoringFunction(this.props.uid, true, true, true);
                }
            }

            if (navFlag) {
                // Change navigation state
                let navigation = this.state.navigation;
                this.setState({ navigation: this.onClickNavState(navigation, sectionIndex, numSections) })
            }
        } catch (err) {
            console.log(err)
        }
    }
    renderNavBottomButtons() {
        try {
            return (<Row className="justify-content-center">
                <ButtonGroup variant="outlined" color="primary">
                    <Button onClick={() => this.renderPreviousSection()}>Previous</Button>
                    <Button onClick={() => this.renderNextSection()}>{"  Next  "}</Button>
                </ButtonGroup>
            </Row>);
        } catch (err) {
            console.log(err)
        }
    }

    onClickNavState(navigation, sectionIndex, numSections) {
        try {
            navigation.currentSection = parseInt(sectionIndex, 10);
            if (navigation.currentSection > 0) {
                navigation.prevSection = navigation.currentSection - 1;
            } else {
                navigation.prevSection = null;
            }
            if (navigation.currentSection >= numSections - 1) {
                navigation.nextSection = null;
            } else {
                navigation.nextSection = navigation.currentSection + 1;
            }
            return navigation
        } catch (error) {
            return navigation
        }
    }

    getQuizData(quizState, sectionIndex, subSectionIndex) {
        console.log("Quiz State", quizState)
        try {
            //   sectionIndex=sectionIndex.toString(10)
            sectionIndex = parseInt(sectionIndex)
            subSectionIndex = parseInt(subSectionIndex)
            const quizStateArray = Object.entries(quizState);

            quizStateArray.map((item, questionIndex) => {
                if (this.props.moduleData.content !== undefined) {

                    const subModuleData = this.props.moduleData.content.subModules[this.props.subModuleId]

                    const questionData = subModuleData.content[sectionIndex][subSectionIndex].questions[questionIndex]


                    let response = [];
                    if (typeof item[1].answers === 'object') {
                        response = item[1].answers.reduce(
                            (out, bool, index) => bool ? out.concat(index) : out,
                            []
                        )
                    } else {
                        response = item[1].answers
                    }

                    let modData = {...this.props.moduleData.content}


                    if (questionData.qType === "open-res") {

                        modData.subModules[this.props.subModuleId].content[sectionIndex][subSectionIndex]
                            .questions[questionIndex].userState.response = response;
                    } else {
                        modData.subModules[this.props.subModuleId].content[sectionIndex][subSectionIndex]
                            .questions[questionIndex].userState.response = response;
                    }

                    if (questionData.sectionComplete) {
                        modData.subModules[this.props.subModuleId].content[sectionIndex].userState.completed = true
                        // update the state of next section to be unlocked
                        if (modData.subModules[this.props.subModuleId].content[parseInt(sectionIndex) + 1] !== undefined) {
                            modData.subModules[this.props.subModuleId].content[parseInt(sectionIndex) + 1].userState.locked = false
                        }
                    }

                    // Update the topic state as complete and unlock next section
                    if (questionData.topicComplete) {
                        if (this.props.subModuleId === (this.props.moduleData.content.subModules.length - 1)) {
                            this.setState({ moduleComplete: true })
                        }

                        modData.subModules[this.props.subModuleId].userState.completed = true
                        if (modData.subModules[this.props.subModuleId + 1].userState !== undefined) {
                            modData.subModules[this.props.subModuleId + 1].userState.locked = false
                        }

                    }

                    console.log("Mod data before fn", modData)
                    // Add progress
                    modData.userState.progress += 1;
                    this.props.updateModuleData(this.props.uid, modData)
                    callScoringFunction(this.props.uid, true, true, true);
                    this.setState({ triggerChange: !this.state.triggerChange })
                }
                return null;
            })
        } catch (error) {
            console.log(error)
        }
    }

    updateDraftResponse = (response, sectionIndex, subSectionIndex) => {
        try {
            sectionIndex = parseInt(sectionIndex)
            subSectionIndex = parseInt(subSectionIndex)

            if (this.props.moduleData.content !== undefined) {
                const subModuleData = this.props.moduleData.content.subModules[this.props.subModuleId]
                const questionData = subModuleData.content[sectionIndex][subSectionIndex]

                
                let modData = this.props.moduleData.content

                modData.subModules[this.props.subModuleId].content[sectionIndex][subSectionIndex]
                    .userState = {response: {draft: response }};

                if (questionData.sectionComplete) {
                    modData.subModules[this.props.subModuleId].content[sectionIndex].userState.completed = true
                    // update the state of next section to be unlocked
                    if (modData.subModules[this.props.subModuleId].content[parseInt(sectionIndex) + 1] !== undefined) {
                        modData.subModules[this.props.subModuleId].content[parseInt(sectionIndex) + 1].userState.locked = false
                    }
                }

                // Update the topic state as complete and unlock next section
                if (questionData.topicComplete) {
                    if (this.props.subModuleId === (this.props.moduleData.content.subModules.length - 1)) {
                        this.setState({ moduleComplete: true })
                    }

                    modData.subModules[this.props.subModuleId].userState.completed = true
                    if (modData.subModules[this.props.subModuleId + 1].userState !== undefined) {
                        modData.subModules[this.props.subModuleId + 1].userState.locked = false
                    }

                }

                console.log("Mod data before fn", modData)
                // Add progress
                modData.userState.progress += 1;

                this.props.updateModuleData(this.props.uid, modData)
                callScoringFunction(this.props.uid, true, true, true);
                this.setState({ triggerChange: !this.state.triggerChange })
            }
            return null;
        } catch (error) {
            console.log(error)
        }
    }

    updateDraftQuizResponse = (response, sectionIndex, subSectionIndex) => {
        try {
            sectionIndex = parseInt(sectionIndex)
            subSectionIndex = parseInt(subSectionIndex)

            if (this.props.moduleData.content !== undefined) {
                const subModuleData = this.props.moduleData.content.subModules[this.props.subModuleId]
                const questionData = subModuleData.content[sectionIndex][subSectionIndex]

                
                let modData = this.props.moduleData.content

                modData.subModules[this.props.subModuleId].content[sectionIndex][subSectionIndex]
                    .userState.response.quiz = response;

                if (questionData.sectionComplete) {
                    modData.subModules[this.props.subModuleId].content[sectionIndex].userState.completed = true
                    // update the state of next section to be unlocked
                    if (modData.subModules[this.props.subModuleId].content[parseInt(sectionIndex) + 1] !== undefined) {
                        modData.subModules[this.props.subModuleId].content[parseInt(sectionIndex) + 1].userState.locked = false
                    }
                }

                // Update the topic state as complete and unlock next section
                if (questionData.topicComplete) {
                    if (this.props.subModuleId === (this.props.moduleData.content.subModules.length - 1)) {
                        this.setState({ moduleComplete: true })
                    }

                    modData.subModules[this.props.subModuleId].userState.completed = true
                    if (modData.subModules[this.props.subModuleId + 1].userState !== undefined) {
                        modData.subModules[this.props.subModuleId + 1].userState.locked = false
                    }

                }

                console.log("Mod data before fn", modData)
                // Add progress
                modData.userState.progress += 1;

                this.props.updateModuleData(this.props.uid, modData)
                callScoringFunction(this.props.uid, true, true, true);
                this.setState({ triggerChange: !this.state.triggerChange })
            }
            return null;
        } catch (error) {
            console.log(error)
        }
    }


    updateMcqWithHints(response, sectionIndex, subSectionIndex) {
        try {
            sectionIndex = parseInt(sectionIndex)
            subSectionIndex = parseInt(subSectionIndex)

            if (this.props.moduleData.content !== undefined) {
                const subModuleData = this.props.moduleData.content.subModules[this.props.subModuleId]
                const questionData = subModuleData.content[sectionIndex][subSectionIndex]

                
                let modData = this.props.moduleData.content

                modData.subModules[this.props.subModuleId].content[sectionIndex][subSectionIndex]
                    .userState.response = response;

                if (questionData.sectionComplete) {
                    modData.subModules[this.props.subModuleId].content[sectionIndex].userState.completed = true
                    // update the state of next section to be unlocked
                    if (modData.subModules[this.props.subModuleId].content[parseInt(sectionIndex) + 1] !== undefined) {
                        modData.subModules[this.props.subModuleId].content[parseInt(sectionIndex) + 1].userState.locked = false
                    }
                }

                // Update the topic state as complete and unlock next section
                if (questionData.topicComplete) {
                    if (this.props.subModuleId === (this.props.moduleData.content.subModules.length - 1)) {
                        this.setState({ moduleComplete: true })
                    }

                    modData.subModules[this.props.subModuleId].userState.completed = true
                    if (modData.subModules[this.props.subModuleId + 1].userState !== undefined) {
                        modData.subModules[this.props.subModuleId + 1].userState.locked = false
                    }

                }

                console.log("Mod data before fn", modData)
                // Add progress
                modData.userState.progress += 1;

                // For oral, if all mcqs in subtopic 3 have been answered, unlock the lecture again:
                if (modData.type === 'oral'){
                    if (modData.userState.progress >= 16) {
                        modData.subModules[this.props.subModuleId].content[1].userState.locked = false;
                        modData.subModules[this.props.subModuleId].content[3].userState.locked = false;
                    }
                }

                this.props.updateModuleData(this.props.uid, modData)
                callScoringFunction(this.props.uid, true, true, true);
                this.setState({ triggerChange: !this.state.triggerChange })
            }
            return null;
        } catch (error) {
            console.log(error)
        }
    }

    async renderSection(parentComponent, sectionData, sectionIndex, subSectionKey) {

        try {
            if (subSectionKey !== 'subSectionTitle') {
                const subSectionContent = sectionData[subSectionKey]

                if (subSectionContent.type === 'text') {

                    const div = document.createElement("div");
                    ReactDOM.render(
                        <Container>
                            {(subSectionContent.subSectionTitle !== undefined || subSectionContent.subSectionTitle !== '') &&
                                (<strong>{ReactHtmlParser(subSectionContent.subSectionTitle)}</strong>)}
                            <p dangerouslySetInnerHTML={{ __html: subSectionContent.text }} />
                        </Container>,
                        parentComponent.appendChild(div)
                    );
                }

                if (subSectionContent.type === 'image') {
                    if (subSectionContent.srcType === 'url') {

                        const div = document.createElement("div");
                        div.setAttribute("id", "image-" + sectionIndex + "-" + subSectionKey);

                        ReactDOM.render(
                            <div style={{width: "100%", textAlign: "center"}}>
                                <img src='' style={{width: "50%",}}/>
                            </div>,
                            parentComponent.appendChild(div)
                        );

                        storage.ref(subSectionContent.url).getDownloadURL().then(function (url) {
                            ReactDOM.render(
                                <div style={{width: "100%", textAlign: "center"}}>
                                <img src={url} style={{width: "50%",}}/>
                                </div>,
                                div
                            )
                        })

                    }
                }

                if (subSectionContent.type === 'textIndent') {

                    const div = document.createElement("div");
                    ReactDOM.render(
                        <Container>
                            {(subSectionContent.subSectionTitle !== undefined || subSectionContent.subSectionTitle !== '') &&
                                (<strong>{ReactHtmlParser(subSectionContent.subSectionTitle)}</strong>)}
                            <p dangerouslySetInnerHTML={{ __html: subSectionContent.text }} style={{ textIndent: '-3rem', marginLeft: '3rem' }} />
                        </Container>,
                        parentComponent.appendChild(div)
                    );
                }

                if (subSectionContent.type === 'imgLink') {
                    const div = document.createElement("div");
                    let srcPromises = [];
                    let urlList = [];

                    subSectionContent.images.forEach(element => {
                        srcPromises.push(storage.ref(element.src).getDownloadURL());
                        urlList.push({ link: element.link, urlType: element.urlType });
                    });

                    const srcList = await Promise.all(srcPromises);

                    const handleClick = (idx) => {
                        try {
                            if (urlList[idx].urlType === 'external') {
                                const newWindow = window.open(urlList[idx].link, '_blank', 'noopener,noreferrer');
                                if (newWindow) newWindow.opener = null;
                            } else if (urlList[idx].urlType === 'internal') {
                                this.props.onSwitchPageType(urlList[idx].link, 0);
                            }
                        } catch (err) {
                            console.log(err)
                        }
                    }

                    ReactDOM.render(
                        <Container>
                            {(subSectionContent.subSectionTitle !== undefined || subSectionContent.subSectionTitle !== '') &&
                                (<strong>{ReactHtmlParser(subSectionContent.subSectionTitle)}</strong>)}
                            <Grid container justify="space-around">
                                {srcList.map((src, idx) => (
                                    <Grid item xs={4} key={idx}>
                                                 <div 
                                                 style={{ borderStyle:'outset', borderRadius: '10px', textAlign: 'center', padding: '1.5rem', cursor: 'pointer'}}
                                                 onClick={() => handleClick(idx)}>
                                                         <Typography variant='h6' style={{ marginBottom: '1rem' }}>
                                                             <strong>{subSectionContent?.images[idx]?.header}</strong>
                                                         </Typography>
                                                         <img
                                                          src={src}
                                                          alt={subSectionContent?.images[idx]?.header}
                                                          style={{height: '100px', width: 'auto'}}/>
                                                </div>
                                    </Grid>))}
                            </Grid>

                        </Container>,
                        parentComponent.appendChild(div)
                    );
                }

                if (subSectionContent.type === 'video') {
                    if (subSectionContent.srcType === 'url') {

                        const div = document.createElement("div");
                        div.setAttribute("id", "video-" + sectionIndex + "-" + subSectionKey);
                        ReactDOM.render(
                            <VideoPlayer
                                title={subSectionContent.subSectionTitle}
                                url={''}
                            />,
                            parentComponent.appendChild(div)
                        );

                        storage.ref(subSectionContent.url).getDownloadURL().then(function (url) {
                            ReactDOM.render(
                                <VideoPlayer
                                    title={subSectionContent.subSectionTitle}
                                    url={url}
                                />,
                                div
                            )
                        })

                    }
                }

                if (subSectionContent.type === 'quiz') {
                    const div2 = document.createElement("div");
                    ReactDOM.render(
                        <Container style={subSectionContent.subSectionTitle !== "" ? { marginTop: '3rem' } : {}}>
                            <span><strong>{ReactHtmlParser(subSectionContent.subSectionTitle)}</strong></span>
                            <Quiz
                                content={sectionData}
                                index={subSectionKey}
                                questions={subSectionContent.questions}
                                handleQuizData={this.getQuizData}
                                hideWordCount={subSectionContent.questions[0].hideWordCount}
                                sectionIndex={sectionIndex}
                                subSectionKey={subSectionKey} />
                            <br /><br />
                        </Container>,
                        parentComponent.appendChild(div2)
                    );
                }

                if (subSectionContent.type === 'quizNew') {
                    const div2 = document.createElement("div");
                    ReactDOM.render(
                        <Container style={subSectionContent.subSectionTitle !== "" ? { marginTop: '3rem' } : {}}>
                            <span><strong>{ReactHtmlParser(subSectionContent.subSectionTitle)}</strong></span>
                                <QuizNew
                                    question={subSectionContent.questions[0]}
                                    handleQuizData={this.getQuizData}
                                    sectionIndex={sectionIndex}
                                    subSectionKey={subSectionKey}
                                    previousResponse={sectionData[subSectionKey].questions[0].userState.response}
                                />
                            <br /><br />
                        </Container>,
                        parentComponent.appendChild(div2)
                    );
                }

                if (subSectionContent.type === 'mcqWithHints') {
                    const div2 = document.createElement("div");
                    ReactDOM.render(
                        <Container style={subSectionContent.subSectionTitle !== "" ? { marginTop: '3rem' } : {}}>
                            <span><strong>{ReactHtmlParser(subSectionContent.subSectionTitle)}</strong></span>
                            <QuizWritten
                                previousResponse={sectionData[subSectionKey].userState.response}
                                updateFunction={this.updateMcqWithHints}
                                questionData={subSectionContent.questionData}
                                sectionIndex={sectionIndex}
                                subSectionKey={subSectionKey} />
                            <br /><br />
                        </Container>,
                        parentComponent.appendChild(div2)
                    );
                }

                if (subSectionContent.type === 'pdf') {
                    let urlSection = [];
                    subSectionContent.url.forEach((i) => {
                        urlSection.push(storage.ref(i).getDownloadURL());
                    });

                    urlSection = await Promise.all(urlSection);

                    const div = document.createElement("div")
                    console.log(subSectionContent.instructions)
                    ReactDOM.render(
                        <ReadAnArticle 
                        pdf={urlSection}
                        updateFunction={this.updateMcqWithHints}
                        previousResponse={sectionData[subSectionKey].userState.response}
                        sectionIndex={sectionIndex}
                        subSectionKey={subSectionKey}/>,
                        parentComponent.appendChild(div)
                    )
                }

                if (subSectionContent.type === 'firstAssessment') {
                    let urlSection = [];
                    subSectionContent.url.forEach((i) => {
                        urlSection.push(storage.ref(i).getDownloadURL());
                    });

                    urlSection = await Promise.all(urlSection);

                    let sortedURLs = []

                    urlSection.forEach((url) => {
                        if(url.includes('Invention')) sortedURLs.unshift(url);
                        else sortedURLs.push(url)
                    })

                    const div = document.createElement("div")
                    //this.setState({pdfContent: sectionData[subSectionKey].userState.response})
                    ReactDOM.render(

                        <FirstDraftSilentReading
                            pdf={sortedURLs}
                            updateDraftResponse={this.updateDraftResponse}
                            updateDraftQuizResponse={this.updateDraftQuizResponse}
                            sectionIndex={sectionIndex}
                            subSectionKey={subSectionKey}
                            previousResponse={sectionData[subSectionKey].userState.response}
                        />,
                        parentComponent.appendChild(div)
                    )
                }

                if(subSectionContent.type === 'secondAssessment'){

                    let urlSection = [];
                    subSectionContent.url.forEach((i) => {
                        urlSection.push(storage.ref(i).getDownloadURL());
                    });

                    urlSection = await Promise.all(urlSection);

                    let sortedURLs = []

                    urlSection.forEach((url) => {
                        if(url.includes('Invention')) sortedURLs.unshift(url);
                        else sortedURLs.push(url)
                    })
                    
                    const div = document.createElement("div")
                    
                    console.log("OVER HERE: ", this.props.moduleData.content.subModules[0].content[6][0].userState.response)
                    ReactDOM.render(
                        <SecondDraftSilentReading
                            pdf={sortedURLs}
                            updateDraftResponse={this.updateDraftResponse}
                            updateDraftQuizResponse={this.updateDraftQuizResponse}
                            sectionIndex={sectionIndex}
                            subSectionKey={subSectionKey}
                            firstDraft={this.props.moduleData.content.subModules[0].content[6][0].userState.response.draft}
                            previousResponse={sectionData[subSectionKey].userState.response}
                        />,
                        parentComponent.appendChild(div)
                    )                    

                }


                if (subSectionContent.type === 'Read Aloud') {
                    const div = document.createElement("div");
                    ReactDOM.render(
                        <ReadAloud allowMultipleSubmit={subSectionContent.allowMultipleSubmit} feedback={subSectionContent.feedback} text={subSectionContent.text} submitRequired={subSectionContent.submitRequired} resourceButtonLink={subSectionContent.resourceButtonLink} saveLocation={subSectionContent.saveLocation}/>,
                        parentComponent.appendChild(div)
                    )

                }

                if (subSectionContent.type === 'practicePresenting') {
                    const div = document.createElement("div");
                    ReactDOM.render(
                        <PracticePresenting 
                        feedback={subSectionContent.feedback} 
                        text={subSectionContent.text} 
                        submitRequired={subSectionContent.submitRequired} 
                        resourceButtonLink={subSectionContent.resourceButtonLink} 
                        saveLocation={subSectionContent.saveLocation}
                        uid={this.props.uid}/>,
                        parentComponent.appendChild(div)
                    )
                }

                if (subSectionContent.type === 'uploadFile') {
                    const div = document.createElement('div');
                    ReactDOM.render(
                        <Upload text={subSectionContent.text} submitRequired={subSectionContent.submitRequired} submitOnce={subSectionContent.submitOnce} fileIdentifierKey={subSectionContent.fileIdentifierKey} saveLocation={subSectionContent.saveLocation}/>,
                        parentComponent.appendChild(div)
                    )

                }

                if (subSectionContent.type === 'vocab') {
                    const div = document.createElement("div");

                    ReactDOM.render(
                        <Highlighter 
                        previousResponse={sectionData[subSectionKey].userState.response} 
                        updateFunction={(res) => this.updateMcqWithHints(res, sectionIndex, subSectionKey)}/>,
                        parentComponent.appendChild(div)
                    )
                }


                if (subSectionContent.type === 'asr') {
                    const div = document.createElement("div");

                    ReactDOM.render(
                        <AsrInterface />,
                        parentComponent.appendChild(div)
                    )
                }

                if (subSectionContent.type === 'selfAssess') {
                    const div = document.createElement("div");
                    ReactDOM.render(
                        <SelfAssessment
                            previousResponse={sectionData[subSectionKey].userState.response}
                            sectionTitle={subSectionContent.sectionTitle} 
                            questionsList={subSectionContent.questionArr} 
                            scale={subSectionContent.scale}
                            updateFunction={this.updateMcqWithHints}
                            sectionIndex={sectionIndex}
                            subSectionKey={subSectionKey}
                            />,
                        parentComponent.appendChild(div)
                    )
                }

                if (subSectionContent.type === 'selfAssess2') {
                    const div = document.createElement("div");
                    ReactDOM.render(
                        <SelfAssessmentSingle 
                        sectionTitle={subSectionContent.sectionTitle} 
                        questionsList={subSectionContent.questionArr}
                        previousResponse={sectionData[subSectionKey].userState.response}
                        updateFunction={(res) => this.updateMcqWithHints(res, sectionIndex, subSectionKey)}
                         />,
                        parentComponent.appendChild(div)
                    );
                }

            }
        } catch (error) {

        }
    }

    render() {

        const subModuleData = this.props.content
        const sectionData = Object.entries(subModuleData.content)
        const title = titleMap[this.props.moduleId]
        const sectionState = null;
        console.log(this.state.moduleComplete)
        return (
            <Container fluid>
                <Row>
                    <Col sm={2}>
                        <br />
                        <TopicTable subModules={this.props.moduleData.content.subModules}
                            renderDefault={this.renderOnSubModuleSwitch}
                            selectPage={this.props.onSwitchPageType}
                            itemId={this.props.subModuleId}
                            backButton={true}
                            triggerChange={this.state.triggerChange}
                        >
                        </TopicTable>
                    </Col>
                    <Col sm={10}>
                        <Container fluid>
                            <br />
                            <Row id="module-name-tab"> <h2>{subModuleData.subModuleTitle}</h2></Row>
                            <br></br>
                            <Row style={{ "padding-left": "15px", "padding-right": "25px" }}>{this.renderNavBar(sectionData, sectionState)}</Row>
                            <br></br>
                            <Row >
                                <Col id="module-content" style={{ 'minHeight': "50vh" }}>{ }</Col>
                            </Row>
                            {/* <br></br>
                            <Row >
                                <Col id="bottom-nav">{this.renderNavBottomButtons()}</Col>
                            </Row> */}

                            {this.state.moduleComplete &&
                                <Row style={{ justifyContent: "center", alignItems: "center" }}>
                                    <Alert className="popup-alert" severity="success">
                                        <AlertTitle>Congratulations! You have completed the {title} Module.
                                            You can now download your certificate of completion by going to the
                                            <a
                                                style={{ "padding": "0px", "paddingLeft": "1px", "color": "#0000EE" }}
                                                onClick={() => { this.toModule() }}>
                                                {title} Homepage.
                                            </a>
                                        </AlertTitle>
                                    </Alert>

                                </Row>}
                            <br></br>
                        </Container>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateTOProps = (state) => {
    return {
        uid: state.firebase.auth.uid,
        moduleData: state.moduleData.moduleContent
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModuleById: (moduleId) => dispatch(fetchModuleById(moduleId)),
        updateModuleData: (uid, moduleData) => dispatch(updateModuleData(uid, moduleData))
    };
};

export default connect(mapStateTOProps, mapDispatchToProps)(SubModuleWrapper);